import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Item No.', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'description', true, 'asc', 'left'),
  new TableColumn('Price', 'price', true, 'asc', 'right'),
  new TableColumn('Active', 'deleted', true, 'asc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }
